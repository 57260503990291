import { Link } from 'gatsby';
import React from 'react';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';
import LIMITS from 'gatsby-theme-husky/src/shared/static/limits';
import { stringUtils, customStructurePickerUtils } from 'gatsby-theme-husky/src/utils';
import { ArticleData } from '@shared/types/common/ArticleData';

const ArticleCardText = ({ title, url, articleBody }) => {
  const titleCapitalized = stringUtils.capitalizeEsp(title);

  const getArticleIntroductionTextWithoutHtml = (
    articleItem: ArticleData.ArticleBody[]
  ): string | null => {
    return customStructurePickerUtils.getStructurePropertiesDataWOHtml(
      articleItem,
      ARTICLES.INTRODUCTION,
      ARTICLES.INTRO_TEXT,
      80
    );
  };

  return (
    <>
      <h3 className="article-card__article-title">
        <Link to={url} className="article-card__link">
          {stringUtils.sliceText(titleCapitalized, LIMITS.ARTICLE_CARD_TITLE)}
        </Link>
      </h3>

      <div className="article-card__article-text">
        {getArticleIntroductionTextWithoutHtml(articleBody)}
      </div>
    </>
  );
};

export default ArticleCardText;
